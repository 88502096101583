<template>
  <!--About Section-->
  <section class="about-section hide">
    <div class="auto-container">

      <div v-if="layout === 'regular'" class="row clearfix">
        <!--Image Column-->
        <div class="image-column col-lg-6 col-md-12 col-sm-12">
          <div class="inner-column">
            <div class="image wow slideInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
              <img v-bind:src="image" alt />
            </div>
          </div>
        </div>        
        <content-column
          :lead = "lead"
          :title="title"
          :slug="slug"
          :subtitle="subtitle"
          :suptitle="suptitle"
          :content="content"
          :icons="icons"
          :iconsColumns="iconsColumns"
          :shouldShowButton="shouldShowButton"
        />
      </div>

      <div v-if="layout === 'alt'" class="row clearfix">      
        <content-column
          :lead = "lead"
          :title="title"
          :slug="slug"
          :subtitle="subtitle"
          :suptitle="suptitle"
          :content="content"
          :icons="icons"
          :iconsColumns="iconsColumns"
          :shouldShowButton="shouldShowButton"
        />
        <!--Image Column-->
        <div class="image-column col-lg-6 col-md-12 col-sm-12">
          <div class="inner-column">
            <div class="image wow slideInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
              <img v-bind:src="image" alt />
            </div>
          </div>
        </div>  
      </div>

    </div>
  </section>
  <!--End About Section-->
</template>

<script>
var $ = global.jQuery;

import ContentColumn from "./ContentColumn.vue";

export default {
  name: "Page",
  components: {
    ContentColumn
  },
  props: {
    slug: String,
    layout: {
      type: String,
      default: 'regular',
      validator: (value) => ['regular', 'alt'].includes(value)
    },
    iconsColumns: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      title: String,
      subtitle: String,
      suptitle: String,
      content: String,
      image: String,
      icons: [],
    };
  },
  computed: {
    
  },
  mounted() {
    fetch(`https://panel.devcom.com.pl/wp-json/wp/v2/strona?slug=${this.slug}`)
      .then((r) => r.json())
      .then((res) => {
        this.content = res.map((x) => x.tresc)[0];
        this.lead = res.map((x) => x.lead)[0];
        this.title = res.map((x) => x.title.rendered)[0];
        this.suptitle = res.map((x) => x.nadtytul)[0];
        this.subtitle = res.map((x) => x.podtytul)[0];
        this.image = res.map((x) => x._media[x.zdjecie.ID]?.large)[0];
        this.icons = res.map((x) => x.ikona)[0]?.split('\n')?.map(i => i.split('|'));
        this.shouldShowButton = res.map((x) => x.pokaz_przycisk)[0] == '1';
        $(".hide").css("opacity", "1");
      })
      .then(() => {
        window.scrollTo(0, 0);
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.hide {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}
.image img {
  max-width: 80%;
  margin: auto;
}
</style>
