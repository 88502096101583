<template>
  <main-layout>
    <page slug="sprzedaz-i-wdrozenia" />
  </main-layout>
</template>

<script>
import MainLayout from '../layouts/Main.vue'
import Page from '../components/Page.vue'

export default {
  components: {
    MainLayout,
    Page
  }
}
</script>
