export default {
  '/o-nas': {
    url: 'About',
    title: 'O nas'
  },
  '/outsourcing-it': {
    url: 'Outsourcing',
    title: 'Outsourcing'
  },
  '/serwis-komputerowy': {
    url: 'Service',
    title: 'Serwis'
  },
  '/sprzedaz-i-wdrozenia': {
    url: 'Sales',
    title: 'Sprzedaż i wdrożenia'
  },
  '/insert': {
    url: 'Insert',
    title: 'Insert'
  },
  '/insert/.*': {
    url: 'InsertSubpage',
    title: 'Insert'
  },
  '/': {
    url: 'Home',
    title: 'Twój partner w branży IT'
  }
}
