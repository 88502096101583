<template>
  <main-layout>
    
    <page v-for="(slug, index) in slugs" :key="slug" :slug="slug" :layout="(index % 2 ? 'alt' : 'regular')" />


    <div align="center"><iframe id="insert_aktualna_promocja_doublebill" name="insert_aktualna_promocja_doublebill" src="https://bannery.insert.com.pl/aktualna_promocja_doublebill" width="750" height="200" frameborder="0" scrolling="no"><!--insert_aktualna_promocja_doublebill--></iframe></div>
    
  </main-layout>
</template>

<script>
import MainLayout from '../layouts/Main.vue'
import Page from '../components/Page.vue'

export default {
  components: {
    MainLayout,
    Page
  },

  data() {
    return {
      slugs: []
    }
  },

  mounted() {
    const insertCategoryId = 7;
    const query = `https://panel.devcom.com.pl/wp-json/wp/v2/strona?categories=${insertCategoryId}&_fields=title,slug,menu_order&orderby=menu_order&order=asc&per_page=100`;
    fetch(query)
      .then((response) => response.json())
      .then((jsonResponse) => {
        this.slugs = jsonResponse.map((page) => page.slug);
        console.log(this.slugs);
      });
  },
}
</script>
