<template>
  <page slug="o-nas"/>
</template>

<script>
import Page from "../components/Page.vue";

export default {
  name: "AboutUs",
  components: {
    Page,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
