<template>        
  <!--Content Column-->
  <div class="content-column col-lg-6 col-md-12 col-sm-12">
    <div class="inner-column">
      <div class="sec-title">
        <div class="title">{{ suptitle }}</div>
        <h2 v-html="title"></h2>
      </div>
      <div class="bold-text">{{ subtitle }}</div>
      <div class="text" v-html="nl2p"></div>
      <div class="row clearfix">
        <div v-for="icon in icons" :key="icon" :class="iconsColumnsClass">
          <ul class="reason-list-two">
            <li>
              <i :class="icon[0]"></i>
              {{ icon[1] }}
            </li>
          </ul>
        </div>
      </div>
      <a v-if="shouldShowButton" v-bind:href="'/insert/' + slug" class="theme-btn btn-style-one">Czytaj więcej</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentColumn",
  props: {
    lead: String,
    title: String,
    slug: String,
    subtitle: String,
    suptitle: String,
    content: String,
    icons: [],
    iconsColumns: {
      type: Number,
      default: 2
    },
    shouldShowButton: Boolean,
  },
  data() {
  },
  computed: {
    nl2p() {
      const shouldShowLeadOnly = this.shouldShowButton;
      return (shouldShowLeadOnly ? this.lead : this.content)
        ?.toString()
        ?.split("\r\n\r\n")
        ?.map((p) => `<p>${p}</p>`)
        ?.join("\n");
    },
    iconsColumnsClass() {
      const columns = 12 / this.iconsColumns;
      return `col-lg-${columns} col-md-${columns} col-sm-12`;
    }
  },
  mounted() {
  },
};
</script>

<style>
</style>
