<template>
  <div class="page-wrapper">
    <!-- Preloader -->
    <div class="preloader"></div>

    <!-- Main Header-->
    <header class="main-header header-style-one">
      <!--Header Top-->
      <div class="header-top">
        <div class="auto-container">
          <div class="clearfix">
            <!--Top Left-->
            <div class="top-left clearfix">
              <ul class="links clearfix">
                <li>
                  <a href="#">
                    <span class="icon fa fa-map-marker"></span>Al. Korfantego
                    81, 40-161 Katowice
                  </a>
                </li>
                <li>
                  <a href="mailto:sklep@devcom.com.pl">
                    <span class="icon fa fa-envelope"></span>sklep@devcom.com.pl
                  </a>
                </li>
              </ul>
            </div>

            <!--Top Right-->
            <div class="top-right pull-right">
              <div class="text">Twój partner w branży IT</div>
            </div>
          </div>
        </div>
      </div>

      <!--Header-Upper-->
      <div class="header-upper">
        <div class="auto-container">
          <div class="clearfix">
            <div class="pull-left logo-box">
              <div class="logo logo-devcom">
                <a href="/">
                  <img src="@/assets/logo-devcom-1.png" alt title />
                </a>
              </div>
            </div>

            <div class="pull-right upper-right clearfix">
              <!--Info Box-->
              <div class="upper-column info-box">
                <div class="icon-box">
                  <span class="fa fa-clock"></span>
                </div>
                <ul>
                  <li><strong>08:00 – 16:00</strong> (śr. do 17)</li>
                  <li>Od poniedziałku do piątku</li>
                </ul>
              </div>

              <!--Info Box-->
              <div class="upper-column info-box">
                <div class="icon-box">
                  <span class="fa fa-phone"></span>
                </div>
                <ul>
                  <li>
                    <strong><a href="tel:327069030,83">32 706 90 30</a></strong> (w. 83)
                  </li>
                  <li>serwis: <a href="tel:327069030,84">32 706 90 30</a> (w. 84)</li>
                </ul>
              </div>

              <!--Info Box-->
              <div class="upper-column info-box">
                <div class="icon-box">
                  <span class="fa fa-headset"></span>
                </div>
                <ul>
                  <li>
                    <strong><a href="https://www.898.tv/devcom">Team Viewer</a></strong>
                  </li>
                  <li><a href="https://www.898.tv/devcom">Połącz się zdalnie</a></li>
                </ul>
              </div>

              <!--Info Box-->
              <div class="upper-column info-box">
                <ul class="social-nav">
                  <li>
                    <!-- <a href="https://www.facebook.com/devcomII/">
                      <span class="fab fa-facebook-f"></span>
                    </a> -->
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--End Header Upper-->

      <!--Header Lower-->
      <div class="header-lower">
        <div class="auto-container">
          <div class="nav-outer clearfix">
            <!-- Main Menu -->
            <nav class="main-menu navbar-expand-md">
              <div class="navbar-header">
                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                </button>
              </div>

              <div
                class="navbar-collapse collapse clearfix"
                id="navbarSupportedContent"
              >
                <ul class="navigation clearfix">
                  <li
                    v-for="menuItem in menuItems"
                    :key="menuItem.name"
                    :class="{ current: path === menuItem.url }"
                  >
                    <template v-if="menuItem.url.includes('#')">
                      <a v-on:click="scrollToAnhor(menuItem.url)">{{ menuItem.name }}</a>
                    </template>
                    <template v-else>
                      <v-link :href="menuItem.url">{{ menuItem.name }}</v-link>
                    </template>
                  </li>
                </ul>
              </div>
            </nav>
            <!-- Main Menu End-->

            <div class="outer-box clearfix">
              <div class="advisor-box">
                <a
                  href="http://katalog.devcom.com.pl/oferta"
                  class="theme-btn advisor-btn"
                  >Katalog produktów</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--End Header Lower-->

      <!--Sticky Header-->
      <div class="sticky-header">
        <div class="auto-container clearfix">
          <!--Logo-->
          <div class="logo pull-left">
            <a href="/" class="img-responsive">
              <img src="@/assets/logo-devcom-1.png" alt title />
            </a>
          </div>

          <!--Right Col-->
          <div class="right-col pull-right">
            <!-- Main Menu -->
            <nav class="main-menu navbar-expand-md">
              <div class="navbar-header">
                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent1"
                  aria-controls="navbarSupportedContent1"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                </button>
              </div>

              <div
                class="navbar-collapse collapse clearfix"
                id="navbarSupportedContent1"
              >
                <ul class="navigation clearfix">
                   <li
                    v-for="menuItem in menuItems"
                    :key="menuItem.name"
                    :class="{ current: path === menuItem.url }"
                  >
                    <template v-if="menuItem.url.includes('#')">
                      <a v-on:click="scrollToAnhor(menuItem.url)">{{ menuItem.name }}</a>
                    </template>
                    <template v-else>
                      <v-link :href="menuItem.url">{{ menuItem.name }}</v-link>
                    </template>
                  </li>
                </ul>
              </div>
            </nav>
            <!-- Main Menu End-->
          </div>
        </div>
      </div>
      <!--End Sticky Header-->
    </header>
    <!--End Main Header -->

    <slot></slot>

    <!--Footer Style Two-->
    <footer
      class="footer-style-two"
      style="background-image: url('')"
      id="kontakt"
    >
      <div class="auto-container">
        <div class="row clearfix">
          <!--Info Column-->
          <div class="info-column col-lg-6 col-md-12 col-sm-12">
            <div class="inner-column">
              <div class="sec-title light">
                <div class="title">Czekamy na Ciebie</div>
                <h2>Kontakt</h2>
              </div>
              <div class="text">
                Firma mieści się na terenie Głównego Instytutu Górnictwa (GIG).
              </div>
              <ul class="list-style-two">
                <li>
                  <span class="icon fas fa-phone"></span>
                  <a href="tel:327069030,83">32 706 90 30 wew. 83</a>
                </li>
                <li>
                  <span class="icon fas fa-phone"></span>
                  <a href="tel:327069030,84">32 706 90 30 wew. 84 (serwis)</a>
                </li>
                <li>
                  <span class="icon fas fa-envelope"></span>sklep@devcom.com.pl
                </li>
                <li>
                  <span class="icon fas fa-map-marker"></span>8.00 do 16.00
                  (środy do 17.00)
                </li>
                <li>
                  <span class="icon fas fa-clock"></span>al. Korfantego 81,
                  Katowice 40-161
                </li>
              </ul>
              <a href="#" class="theme-btn btn-style-one">Zadzwoń</a>
            </div>
          </div>

          <!--Map Column-->
          <div class="map-column col-lg-6 col-md-12 col-sm-12">
            <div class="inner-column">
              <!--Map Outer-->
              <div class="map-outer">
                <!--Map Canvas-->
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2549.994569751697!2d19.025795!3d50.273360000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716ce242ccad64f%3A0x981d98cb11141921!2sDevcom+II.+Salon+sprzeda%C5%BCy+komputer%C3%B3w!5e0!3m2!1spl!2s!4v1427456940434"
                  width="100%"
                  height="500"
                  frameborder="0"
                  style="border: 0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Footer Bottom-->
      <div class="footer-bottom">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="column col-lg-6 col-md-12 col-sm-12">
              <div class="copyright">
                &copy; 2020. Wszystkie prawa zastrzeżone
                <a href="#">Devcom II</a>
              </div>
            </div>
            <div class="column col-lg-6 col-md-12 col-sm-12">
              <ul class="footer-nav">
                <li>
                  <v-link href="/">Start</v-link>
                </li>
                <li>
                  <v-link href="/o-nas">O nas</v-link>
                </li>
                <li>
                  <v-link href="/serwis-komputerowy">Serwis</v-link>
                </li>
                <li>
                  <v-link href="/outsourcing-it">Outsourcing IT</v-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!--End Footer Style Two-->
  </div>
  <!--End pagewrapper-->
</template>

<script lang="ts">
import VLink from "../components/VLink.vue";

var $ = global.jQuery;
var WOW = global.WOW;

const menuItems = [
  { url: "/", name: "Start" },
  { url: "/o-nas", name: "O nas" },
  { url: "/serwis-komputerowy", name: "Serwis" },
  { url: "/outsourcing-it", name: "Outsourcing IT" },
  { url: "/sprzedaz-i-wdrozenia", name: "Sprzedaż i wdrożenia" },
  { url: "#kontakt", name: "Kontakt" },
];

export default {
  components: {
    VLink,
  },

  data() {
    return {
      menuItems,
    };
  },

  mounted() {
    if ($(".wow").length) {
      var wow = new WOW({
        boxClass: "wow", // animated element css class (default is wow)
        animateClass: "animated", // animation css class (default is animated)
        offset: 0, // distance to the element when triggering the animation (default is 0)
        mobile: true, // trigger animations on mobile devices (default is true)
        live: true, // act on asynchronously loaded content (default is true)
      });
      wow.init();
    }
  },

  computed: {
    path() {
      return window.location.pathname;
    },
  },

  methods: {
    scrollToAnhor(name) {
      $("html, body").animate(
        {
          scrollTop: $(name).offset().top,
        },
        1500
      );
    },
  },
};
</script>

<style>
</style>