<template>
      
  <!--Sponsors Section-->
  <section class="sponsors-section style-two">
    <div class="auto-container">
        
          <div class="sponsors-outer">
              <!--Sponsors Carousel-->
              <ul class="sponsors-carousel owl-carousel owl-theme">
                  <li v-for="partner in partners" v-bind:key="partner" class="slide-item">
                    <figure class="image-box">
                      <a :href="partner.link"><img :src="partner.image" alt=""></a>
                    </figure>
                  </li>
              </ul>
          </div>
          
      </div>
  </section>
  <!--End Sponsors Section-->

</template>

<script>
var $ = global.jQuery;

export default {
  name: "Partners",
  props: {
  },
  data() {
    return {
      partners: []
    };
  },
  mounted() {
    fetch(`https://panel.devcom.com.pl/wp-json/wp/v2/partner`)
      .then(r => r.json())
      .then(data => {
        data.forEach(partner => {
          this.partners.push({
            title: partner.title.rendered,
            image: partner._media[partner.zdjecie.ID].large,
            link: partner.link,
          })
        });
        
      });    
  },
  updated() {
    $('.sponsors-carousel').owlCarousel({
      loop:true,
      margin:50,
      nav:true,
      smartSpeed: 500,
      autoplay: 4000,
      navText: [ '<span class="fas fa-angle-left"></span>', '<span class="fas fa-angle-right"></span>' ],
      responsive:{
        0:{
          items:1
        },
        480:{
          items:2
        },
        600:{
          items:3
        },
        800:{
          items:4
        },
        1024:{
          items:5
        }
      }
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .owl-stage {
    display: flex;
    align-items: center;
  }
</style>
