<template>
  <div class="page-wrapper">
   
    <!-- Preloader -->
    <div class="preloader"></div>
   
    <!--Error Section-->
    <section class="error-section" style="background-image:url(images/background/7.jpg)">
      <div class="auto-container">
          <div class="content">
              <h1>404</h1>
                <h2>Nie znaleiono strony</h2>
                <div class="text">Strona, której poszukujesz, najprawdopodobniej nie istnieje!</div>
                <v-link href="/" class="theme-btn btn-style-one">Przejdź do strony głównej</v-link>
            </div>
        </div>
    </section>
    <!--End Error Section-->
    
    
</div>
<!--End pagewrapper-->
</template>

<script>
import VLink from '../components/VLink.vue'

export default {
  components: {
    VLink
  }
}
</script>
