import { createApp, h } from 'vue'
import routes from './routes'

const SimpleRouterApp = {
  data: () => ({
    currentRoute: window.location.pathname
  }),

  computed: {
    ViewComponent () {
      const routesKeys = Array.from(Object.keys(routes));
      const urlSanitized = this.currentRoute.replace(/#.*$/, '');
      const currentRouteKey = routesKeys.find(route => urlSanitized.match(`^${route}$`));
      const desiredRoute = routes[currentRouteKey];
      const matchingPage = desiredRoute?.url || '404'
      document.title = 'Devcom - ' + desiredRoute?.title;
      return require(`./pages/${matchingPage}.vue`).default
    }
  },

  render () {
    return h(this.ViewComponent)
  },

  created () {
    window.addEventListener('popstate', () => {
      this.currentRoute = window.location.pathname
    })
  }
}


const app = createApp(SimpleRouterApp);
app.mount('#app');

