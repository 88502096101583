<template>
  <main-layout>
    <page slug="outsourcing" iconsColumns="1" />
    <page slug="informatyczne-wsparcie-firm" layout="alt" />
  </main-layout>
</template>

<script>
import MainLayout from '../layouts/Main.vue'
import Page from '../components/Page.vue'

export default {
  components: {
    MainLayout,
    Page
  }
}
</script>
