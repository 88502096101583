<template>
  <!--Main Slider-->
  <section class="main-slider">
    <div
      class="rev_slider_wrapper fullwidthbanner-container"
      id="rev_slider_one_wrapper"
      data-source="gallery"
    >
      <div
        class="rev_slider fullwidthabanner"
        id="rev_slider_one"
        data-version="5.4.1"
      >
        <ul>
          <li
            v-for="(slide, index) in slides"
            v-bind:key="slide"
            data-description="Slide Description"
            data-easein="default"
            data-easeout="default"
            data-fsmasterspeed="1500"
            data-fsslotamount="7"
            data-fstransition="fade"
            data-hideafterloop="0"
            data-hideslideonmobile="off"
            v-bind:data-index="index"
            data-masterspeed="default"
            data-param1=""
            data-param10=""
            data-param2=""
            data-param3=""
            data-param4=""
            data-param5=""
            data-param6=""
            data-param7=""
            data-param8=""
            data-param9=""
            data-rotate="0"
            data-saveperformance="off"
            data-slotamount="default"
            data-thumb="images/main-slider/AdobeStock_142370448_Preview.jpeg"
            data-title="Slide Title"
            data-transition="parallaxvertical"
          >
            <img
              alt=""
              class="rev-slidebg"
              data-bgfit="cover"
              data-bgparallax="10"
              data-bgposition="top center"
              data-bgrepeat="no-repeat"
              data-no-retina=""
              v-bind:src="slide?.image"
            />

            <div
              class="tp-caption"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingtop="[0,0,0,0]"
              data-responsive_offset="on"
              data-type="text"
              data-height="none"
              data-width="['720','720','650','450']"
              data-whitespace="normal"
              data-hoffset="['15','15','15','15']"
              data-voffset="['-120','-160','-130','-135']"
              data-x="['left','left','left','left']"
              data-y="['middle','middle','middle','middle']"
              data-textalign="['top','top','top','top']"
              data-frames='[{"delay":0,"split":"chars","splitdelay":0.1,"speed":2000,"frame":"0","from":"x:[-105%];z:0;rX:0deg;rY:0deg;rZ:-90deg;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
            >
              <div class="title">{{ slide.suptitle }}</div>
            </div>

            <div
              class="tp-caption"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingtop="[0,0,0,0]"
              data-responsive_offset="on"
              data-type="text"
              data-height="none"
              data-width="['850','720','650','450']"
              data-whitespace="normal"
              data-hoffset="['15','15','15','15']"
              data-voffset="['-45','-70','-50','-70']"
              data-x="['left','left','left','left']"
              data-y="['middle','middle','middle','middle']"
              data-textalign="['top','top','top','top']"
              data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
            >
              <h2 v-html="renderSpan(slide.title)"></h2>
            </div>

            <div
              class="tp-caption tp-resizeme"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingtop="[0,0,0,0]"
              data-responsive_offset="on"
              data-type="text"
              data-height="none"
              data-whitespace="normal"
              data-width="['720','720','650','450']"
              data-hoffset="['15','15','15','15']"
              data-voffset="['40','40','40','15']"
              data-x="['left','left','left','left']"
              data-y="['middle','middle','middle','middle']"
              data-textalign="['top','top','top','top']"
              data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
            >
              <div class="text">{{ slide.content }}</div>
            </div>

            <div
              class="tp-caption tp-resizeme"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingtop="[0,0,0,0]"
              data-responsive_offset="on"
              data-type="text"
              data-height="none"
              data-width="['720','720','650','450']"
              data-whitespace="normal"
              data-hoffset="['15','15','15','15']"
              data-voffset="['135','140','125','105']"
              data-x="['left','left','left','left']"
              data-y="['middle','middle','middle','middle']"
              data-textalign="['top','top','top','top']"
              data-frames='[{"delay":0,"split":"chars","splitdelay":0.1,"speed":2000,"frame":"0","from":"x:[-105%];z:0;rX:0deg;rY:0deg;rZ:-90deg;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
            >
              <!-- <a href="about.html" class="theme-btn btn-style-one">Oferta</a>
              <a href="about.html" class="theme-btn btn-style-two">Kontakt</a> -->
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--End Main Slider-->
</template>

<script>
var $ = global.jQuery;

export default {
  name: "Slider",
  props: {
    href: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      slides: [],
    };
  },
  methods: {
    go() {},
    renderSpan(value) {
        return value
            .replace(/\[/, '<span class="theme_color">')
            .replace(/\]/, '</span>');
    }
  },
  mounted() {
    fetch(`https://panel.devcom.com.pl/wp-json/wp/v2/slider?orderby=menu_order&order=asc`)
      .then((response) => response.json())
      .then((data) => {
        data.forEach((slide) =>
          this.slides.push({
            content: slide.tekst,
            title: slide.title.rendered,
            suptitle: slide.nadtytul,
            image: slide._media[slide.zdjecie.ID].large,
          })
        );
      });
  },
  updated() {
    $("#rev_slider_one")
      .show()
      .revolution({
        waitForInit: false,
        sliderType: "standard",
        jsFileLocation: "plugins/revolution/js/",
        sliderLayout: "auto",
        dottedOverlay: "none",
        delay: 6000,
        navigation: {
          keyboardNavigation: "off",
          keyboard_direction: "horizontal",
          mouseScrollNavigation: "off",
          mouseScrollReverse: "default",
          onHoverStop: "off",
          touch: {
            touchenabled: "on",
            touchOnDesktop: "off",
            swipe_threshold: 75,
            swipe_min_touches: 1,
            swipe_direction: "horizontal",
            drag_block_vertical: false,
          },
          arrows: {
            style: "gyges",
            enable: true,
            hide_onmobile: true,
            hide_under: 600,
            hide_onleave: false,
            tmp: "",
            left: {
              h_align: "left",
              v_align: "center",
              h_offset: 15,
              v_offset: 30,
            },
            right: {
              h_align: "right",
              v_align: "center",
              h_offset: 15,
              v_offset: 30,
            },
          },
        },
        responsiveLevels: [1200, 1040, 802, 480],
        visibilityLevels: [1200, 1040, 802, 480],
        gridwidth: [1200, 1040, 802, 480],
        gridheight: [750, 600, 500, 400],
        lazyType: "none",
        parallax: {
          type: "scroll",
          origo: "enterpoint",
          speed: 400,
          levels: [
            5,
            10,
            15,
            20,
            25,
            30,
            35,
            40,
            45,
            50,
            46,
            47,
            48,
            49,
            50,
            55,
          ],
        },
        shadow: 0,
        spinner: "off",
        stopLoop: "on",
        stopAfterLoops: 0,
        stopAtSlide: 4,
        shuffle: "off",
        autoHeight: "off",
        hideThumbsOnMobile: "off",
        hideSliderAtLimit: 0,
        hideCaptionAtLimit: 0,
        hideAllCaptionAtLilmit: 0,
        debugMode: false,
        fallbacks: {
          simplifyAll: "off",
          nextSlideOnWindowFocus: "off",
          disableFocusListener: false,
        },
      });
  },
};
</script>

<style>
.tp-bgimg:after {
  content: "\A";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 1s;
  -webkit-transition: all 1s;
}
</style>