<template>
  <main-layout>
    <!--About Section-->
    <section class="about-section hide">
      <div class="auto-container">

        <div class="row clearfix">
          <!--Image Column-->
          <div class="image-column col-lg-6 col-md-12 col-sm-12">
            <div class="inner-column">
              <div class="image wow slideInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                <img v-bind:src="image" alt />
              </div>
            </div>
          </div>

          <!--Content Column-->
          <div class="content-column col-lg-6 col-md-12 col-sm-12">
            <div class="inner-column">
              <div class="sec-title">
                <div class="title">{{ suptitle }}</div>
                <h1 v-html="title"></h1>
              </div>
              <div class="bold-text">{{ subtitle }}</div>
              <div class="text" v-html="nl2p"></div>
              <div class="row clearfix">
                <div v-for="icon in icons" :key="icon" :class="iconsColumnsClass">
                  <ul class="reason-list-two">
                    <li>
                      <i :class="icon[0]"></i>
                      {{ icon[1] }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <!--End About Section-->
  </main-layout>
</template>

<script>
var $ = global.jQuery;

import MainLayout from '../layouts/Main.vue'

export default {
  components: {
    MainLayout,
  },

  data() {
    return {
      slug: window.location.pathname.replace('/insert/', ''),
      title: String,
      subtitle: String,
      suptitle: String,
      content: String,
      image: String,
      icons: []
    }
  },

  mounted() {
    fetch(`https://panel.devcom.com.pl/wp-json/wp/v2/strona?slug=${this.slug}`)
      .then((r) => r.json())
      .then((res) => {
        this.content = res.map((x) => x.tresc)[0];
        this.title = res.map((x) => x.title.rendered)[0];
        this.suptitle = res.map((x) => x.nadtytul)[0];
        this.subtitle = res.map((x) => x.podtytul)[0];
        this.image = res.map((x) => x._media[x.zdjecie.ID]?.large)[0];
        this.icons = res.map((x) => x.ikona)[0]?.split('\n')?.map(i => i.split('|'));
        $(".hide").css("opacity", "1");
      })
      .then(() => {
        document.title = `${this.title} | Devcom`;
        window.scrollTo(0, 0);
      })
      .then(() => {
        window.scrollTo(0, 0);
      });
  },

  computed: {
    nl2p() {
      return this.content
        .toString()
        .split("\r\n\r\n")
        .map((p) => `<p>${p}</p>`)
        .join("\n");
    },
    iconsColumnsClass() {
      const columns = 12 / this.iconsColumns;
      return `col-lg-${columns} col-md-${columns} col-sm-12`;
    }
  }
}
</script>

<style lang="css">
  .hide {
    opacity: 0;
    transition: opacity 0.5s ease-in;
  }
  h1, h2 {
    color:#222222;
    font-weight:700;
  }
  h1 {
    font-size:36px;
    line-height:1.4em;
  }
  h2 {
    font-size:20px;
    line-height:1.4em;
    margin-bottom: 1em;
  }
  .text ul {
    margin-top: 1em;
  }
  .text li {
    list-style-type: disc;
  }
  .text a, .text a * {
    color:#222222 !important;
  }
</style>