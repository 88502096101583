<template>
  <main-layout>
    <div class="page-wrapper">
      <slider />

      <!--Services Section-->
      <section class="services-section">
        <div class="auto-container">
          <div class="sec-title centered">
            <div class="title">Nasze usługi</div>
            <h2>Czym się zajmujemy?</h2>
            <div class="seperater"></div>
          </div>
          <div class="row clearfix">
            <!--Services Block-->
            <div class="services-block col-lg-4 col-md-6 col-sm-12">
              <a :href="'/serwis-komputerowy'">
                <div
                  class="inner-box"
                  style="background-image: url(https://panel.devcom.com.pl/wp-content/uploads/2020/08/jeshoots-com-sMKUYIasyDM-unsplash-300x200.jpg); background-size: cover;"
                >
                  <div class="icon-box">
                    <span class="icon flaticon-sketch"></span>
                  </div>
                  <h3>Serwis komputerowy</h3>
                  <div class="text">
                    Specjalistyczny serwis z bogatym wyposażeniem i doświadczoną
                    kadrą
                  </div>
                </div>
              </a>
            </div>

            <!--Services Block-->
            <div class="services-block col-lg-4 col-md-6 col-sm-12">
              <a :href="'/outsourcing-it'">
                <div
                  class="inner-box"
                  style="background-image: url(https://panel.devcom.com.pl/wp-content/uploads/2020/08/austin-distel-97HfVpyNR1M-unsplash-300x200.jpg); background-size: cover;"
                >
                  <div class="icon-box">
                    <span class="icon flaticon-house-plans"></span>
                  </div>
                  <h3>Outsorcing IT</h3>
                  <div class="text">
                    Profesjonalne wsparcie informatyczne dla firm
                  </div>
                </div>
              </a>
            </div>

            <!--Services Block-->
            <div class="services-block col-lg-4 col-md-6 col-sm-12">
              <a :href="'/sprzedaz-i-wdrozenia'">
                <div
                  class="inner-box"
                  style="background-image: url(https://panel.devcom.com.pl/wp-content/uploads/2020/08/luis-villasmil-4V8uMZx8FYA-unsplash-300x201.jpg); background-size: cover;"
                >
                  <div class="icon-box">
                    <span class="icon flaticon-skyline"></span>
                  </div>
                  <h3>Sprzedaż i wdrożenia</h3>
                  <div class="text">
                    Dostosowane do potrzeb klienta sprzęt i oprogramowanie
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <!--End Services Section-->

      <!--Testimonial Section-->
      <!-- <section
        class="testimonial-section"
        style="background-image: url(images/background/4.jpg)"
      >
        <div class="auto-container"> -->
      <!--Sec Title-->
      <!-- <div class="sec-title light">
            <div class="title">Referencje</div>
            <h2>Co mówią nasi klienci?</h2>
          </div>
          <div class="two-item-carousel owl-carousel owl-theme">
            <div class="testimonial-block">
              <div class="inner-box">
                <div class="image">
                  <img src="images/resource/author-1.jpg" alt="" />
                </div>
                <div class="content">
                  <h3>John Christopher</h3>
                  <div class="rating">
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                  </div>
                  <div class="text">
                    “Makin their way the only way they know how that’s just a
                    little bit more than the law will allow these happy days are
                    you. leads a rag tag fugitive fleet on a lonely”
                  </div>
                </div>
              </div>
            </div>

            <div class="testimonial-block">
              <div class="inner-box">
                <div class="image">
                  <img src="images/resource/author-2.jpg" alt="" />
                </div>
                <div class="content">
                  <h3>Stuakrt Mackey</h3>
                  <div class="rating">
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                  </div>
                  <div class="text">
                    “Makin their way the only way they know how that’s just a
                    little bit more than the law will allow these happy days are
                    you. leads a rag tag fugitive fleet on a lonely”
                  </div>
                </div>
              </div>
            </div>

            <div class="testimonial-block">
              <div class="inner-box">
                <div class="image">
                  <img src="images/resource/author-1.jpg" alt="" />
                </div>
                <div class="content">
                  <h3>John Christopher</h3>
                  <div class="rating">
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                  </div>
                  <div class="text">
                    “Makin their way the only way they know how that’s just a
                    little bit more than the law will allow these happy days are
                    you. leads a rag tag fugitive fleet on a lonely”
                  </div>
                </div>
              </div>
            </div>

            <div class="testimonial-block">
              <div class="inner-box">
                <div class="image">
                  <img src="images/resource/author-2.jpg" alt="" />
                </div>
                <div class="content">
                  <h3>Stuakrt Mackey</h3>
                  <div class="rating">
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                  </div>
                  <div class="text">
                    “Makin their way the only way they know how that’s just a
                    little bit more than the law will allow these happy days are
                    you. leads a rag tag fugitive fleet on a lonely”
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->
      <!--End Testimonial Section-->

      <!--Video Section-->
      <section class="video-section" style="background-image: url('')">
        <div class="auto-container">
          <v-link href="/insert">
            <img src="images/clients/PI.png" class="logo" />
            <h2>Partner Insert</h2>
            <div class="text">
              Dostarczamy programy i usługi wspomagające zarządzanie
              przedsiębiorstwami. Wdrażamy, szkolimy pracowników i zapewniamy
              serwis.
              <br/>
              W ofercie InsERTu znajdują się m.in. programy do obsługi
              kadrowo-płacowej, finansowej i handlowej.
            </div>
          </v-link>
          <br/>
          <a href="/insert" class="theme-btn btn-style-one">Poznaj produkty Insert</a>
        </div>
      </section>
      <!--End Video Section-->

      <!--Video Section-->
      <section class="video-section" style="background-image: url('')">
        <div class="auto-container">
            <img src="images/clients/PQ.png" class="logo" />
            <h2>Partner QNAP</h2>
            <div class="text">
              Jesteśmy Certyfikowanym Partnerem QNAP.
              <br />
              Zapraszamy do kontaktu w sprawie zaproponowania optymalnego dla Państwa rozwiązania QNAP oraz omówienia warunków jego wdrożenia.
              <br/>
              Zaufaj QNAP i zyskaj pewność, że Twoje dane są w dobrych rękach!
            </div>
        </div>
      </section>
      <!--End Video Section-->

      <page slug="outsourcing-it-home" />
      <page slug="serwis-komputerowy-home" layout="alt" />

      <partners />
    </div>
    <!--End pagewrapper-->
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
import Page from "../components/Page.vue";
import Partners from "../components/Partners.vue";
import Slider from "../components/Slider.vue";
import VLink from '../components/VLink.vue';

// var $ = global.jQuery;

export default {
  components: {
    MainLayout,
    Page,
    Partners,
    Slider,
    VLink,
  },
  mounted() {
    // setTimeout(() => {
    //   $(".two-item-carousel").owlCarousel({
    //     loop: true,
    //     margin: 30,
    //     nav: true,
    //     smartSpeed: 700,
    //     autoplay: 5000,
    //     navText: [
    //       '<span class="fa fa-angle-left"></span>',
    //       '<span class="fa fa-angle-right"></span>',
    //     ],
    //     responsive: {
    //       0: {
    //         items: 1,
    //       },
    //       600: {
    //         items: 1,
    //       },
    //       800: {
    //         items: 1,
    //       },
    //       1024: {
    //         items: 2,
    //       },
    //       1200: {
    //         items: 2,
    //       },
    //       1400: {
    //         items: 2,
    //       },
    //       1600: {
    //         items: 2,
    //       },
    //     },
    //   });
    // }, 0);
  },
};
</script>